var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "label",
    attrs: {
      "text": "",
      "color": "transparent"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "#525252",
      "left": "",
      "size": "20"
    }
  }, [_vm._v("$addAdminUser")]), _c('div', {
    staticClass: "font-weight-regular secondary--text text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.$t("page_admin_users_create_title")) + " ")])], 1)], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-weight-regular",
    attrs: {
      "small": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'AdminUsers'
        });
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "secondary",
      "left": ""
    }
  }, [_vm._v("$goBack")]), _vm._v(" " + _vm._s(_vm.$t("go_back")) + " ")], 1)], 1)], 1)], 1), _c('v-divider'), _c('validation-observer', {
    ref: "observer"
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_c('v-card-text', {
    staticClass: "custom-card-text"
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, _vm._l(_vm.fields, function (field) {
    return _c('v-col', {
      key: field.name,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "text-right label-text",
      attrs: {
        "cols": "4",
        "md": "2"
      }
    }, [field.type !== 'file' ? [_vm._v(" " + _vm._s(field.label) + " ")] : [_c('v-avatar', {
      staticClass: "white--text",
      attrs: {
        "color": "grey",
        "size": "40"
      }
    }, [_c('v-img', {
      attrs: {
        "src": !_vm.preview ? _vm.file : _vm.preview,
        "width": "40"
      }
    })], 1)]], 2), field.type === 'text' || field.type === 'email' ? [_c('v-col', {
      attrs: {
        "cols": "8",
        "md": field.additional_field ? '4' : '8'
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": field.name,
        "rules": field.rules
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var errors = _ref.errors;
          return [_c('v-text-field', {
            attrs: {
              "outlined": "",
              "dense": "",
              "flat": "",
              "type": field.type,
              "placeholder": field.placeholder,
              "error-messages": errors,
              "autocomplete": "off"
            },
            model: {
              value: field.value,
              callback: function callback($$v) {
                _vm.$set(field, "value", $$v);
              },
              expression: "field.value"
            }
          })];
        }
      }], null, true)
    })], 1), field.additional_field ? _c('v-col', {
      attrs: {
        "cols": "8",
        "md": field.additional_field ? '4' : '8',
        "offset": "4",
        "offset-md": "0"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": field.additional_field.name,
        "rules": field.additional_field.rules
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref2) {
          var errors = _ref2.errors;
          return [_c('v-text-field', {
            attrs: {
              "outlined": "",
              "dense": "",
              "flat": "",
              "placeholder": field.additional_field.placeholder,
              "error-messages": errors,
              "autocomplete": "off"
            },
            model: {
              value: field.additional_field.value,
              callback: function callback($$v) {
                _vm.$set(field.additional_field, "value", $$v);
              },
              expression: "field.additional_field.value"
            }
          })];
        }
      }], null, true)
    })], 1) : _vm._e()] : field.type === 'file' ? [_c('v-col', {
      attrs: {
        "cols": "8"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": field.name,
        "rules": field.rules
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref3) {
          var errors = _ref3.errors;
          return [_c('v-file-input', {
            ref: "file",
            refInFor: true,
            attrs: {
              "accept": "image/*",
              "placeholder": _vm.$t('choose_file'),
              "outlined": "",
              "dense": "",
              "flat": "",
              "prepend-icon": "",
              "error-messages": errors
            },
            on: {
              "change": _vm.updateFileView
            },
            model: {
              value: field.value,
              callback: function callback($$v) {
                _vm.$set(field, "value", $$v);
              },
              expression: "field.value"
            }
          })];
        }
      }], null, true)
    })], 1)] : field.type === 'switch' ? [_c('v-col', {
      attrs: {
        "cols": "8"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": field.name,
        "rules": field.rules
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref4) {
          var errors = _ref4.errors;
          return [_c('v-switch', {
            attrs: {
              "label": field.value === 1 ? 'Active' : 'Inactive',
              "true-value": 1,
              "false-value": 0,
              "color": "#046C21",
              "error-messages": errors
            },
            model: {
              value: field.value,
              callback: function callback($$v) {
                _vm.$set(field, "value", $$v);
              },
              expression: "field.value"
            }
          })];
        }
      }], null, true)
    })], 1)] : _vm._e()], 2)], 1);
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right label-text",
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("password")) + " ")]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "password",
      "rules": _vm.passwordRules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-text-field', {
          attrs: {
            "outlined": "",
            "dense": "",
            "flat": "",
            "type": _vm.inputPassword ? 'password' : 'text',
            "placeholder": _vm.$t('enter_password'),
            "persistent-placeholder": "",
            "append-icon": "mdi-eye",
            "error-messages": errors,
            "autocomplete": "off"
          },
          on: {
            "click:append": _vm.toggleInputPassword
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        })];
      }
    }])
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right label-text",
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirm_password")) + " ")]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "confirmation"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('v-text-field', {
          attrs: {
            "outlined": "",
            "dense": "",
            "flat": "",
            "type": _vm.inputPassword ? 'password' : 'text',
            "placeholder": _vm.$t('confirm_password'),
            "append-icon": "mdi-eye",
            "error-messages": errors
          },
          on: {
            "click:append": _vm.toggleInputPassword
          },
          model: {
            value: _vm.passwordConfirmation,
            callback: function callback($$v) {
              _vm.passwordConfirmation = $$v;
            },
            expression: "passwordConfirmation"
          }
        })];
      }
    }])
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8",
      "offset-md": "2"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "info-card",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("page_users_create_password_hint_text")) + " ")]), _c('ul', [_c('li', [_vm._v(" " + _vm._s(_vm.$t("page_users_create_password_hint_first_rule_text")) + " "), _c('v-icon', {
    attrs: {
      "color": _vm.containsCorrectLength ? 'success' : 'error',
      "size": "16"
    }
  }, [_vm._v(" " + _vm._s(_vm.containsCorrectLength ? "mdi-check" : "mdi-close") + " ")])], 1), _c('li', [_vm._v(" " + _vm._s(_vm.$t("page_users_create_password_hint_second_rule_text")) + " "), _c('v-icon', {
    attrs: {
      "color": _vm.containsUppercase ? 'success' : 'error',
      "size": "16"
    }
  }, [_vm._v(" " + _vm._s(_vm.containsUppercase ? "mdi-check" : "mdi-close") + " ")])], 1), _c('li', [_vm._v(" " + _vm._s(_vm.$t("page_users_create_password_hint_third_rule_text")) + " "), _c('v-icon', {
    attrs: {
      "color": _vm.containsLowercase ? 'success' : 'error',
      "size": "16"
    }
  }, [_vm._v(" " + _vm._s(_vm.containsLowercase ? "mdi-check" : "mdi-close") + " ")])], 1), _c('li', [_vm._v(" " + _vm._s(_vm.$t("page_users_create_password_hint_fourth_rule_text")) + " "), _c('v-icon', {
    attrs: {
      "color": _vm.containsNumber ? 'success' : 'error',
      "size": "16"
    }
  }, [_vm._v(" " + _vm._s(_vm.containsNumber ? "mdi-check" : "mdi-close") + " ")])], 1), _c('li', [_vm._v(" " + _vm._s(_vm.$t("page_users_create_password_hint_fifth_rule_text")) + " "), _c('v-icon', {
    attrs: {
      "color": _vm.containsSymbol ? 'success' : 'error',
      "size": "16"
    }
  }, [_vm._v(" " + _vm._s(_vm.containsSymbol ? "mdi-check" : "mdi-close") + " ")])], 1)])])], 1)], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_vm.passwordStrength >= 1 ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-btn', {
    staticClass: "label elevation-0 password password-weak",
    attrs: {
      "x-small": "",
      "rounded": "",
      "block": ""
    }
  })], 1) : _vm._e(), _vm.passwordStrength >= 2 ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-btn', {
    staticClass: "label elevation-0 password password-medium",
    attrs: {
      "x-small": "",
      "rounded": "",
      "block": ""
    }
  })], 1) : _vm._e(), _vm.passwordStrength >= 3 ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-btn', {
    staticClass: "label elevation-0 password password-strong",
    attrs: {
      "x-small": "",
      "rounded": "",
      "block": ""
    }
  })], 1) : _vm._e()], 1)], 1), _vm.passwordStrength >= 1 ? _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    "class": "password-".concat(_vm.passwordStrengthText, "-text")
  }, [_vm._v(" パスワード強度： " + _vm._s(_vm.passwordStrengthText) + ". ")])]) : _vm._e()], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-weight-regular",
    attrs: {
      "type": "submit",
      "disabled": _vm.formStatus,
      "loading": _vm.formStatus,
      "color": "primary",
      "depressed": "",
      "small": ""
    }
  }, [_vm._v("登録 ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }